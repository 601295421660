// components/organisms/Header.tsx
import { Box, Flex, Heading, Image, useDisclosure } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { MenuIconButton } from '../../atoms/button/MenuIconButton';
import { Menudrawer } from '../../molecules/Menudrawer';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

export const Header: FC = memo(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { userInfo, user, loading } = useAuth();

  const onClickHome = () => {
    navigate('/');
  };

  const onClickIslogin = () => {
    navigate('/login');
  };

  const onClickSpotSearch = () => {
    navigate('/spot-search');
  };
  const onClickPartSearch = () => {
    navigate('/part-search');
  };

  const onClickWorkManagementForDoctor = () => {
    navigate('/work-management-for-doctor');
  };

  const onClickRegister = () => {
    if (userInfo?.userType === 'hospital') {
      navigate('/hospital-register');
    } else {
      navigate('/register');
    }
  };

  const onClickSetting = () => {
    if (userInfo?.userType === 'hospital') {
      navigate('/hospital-setting');
    } else {
      navigate('/setting');
    }
  };

  const onClickScout = () => {
    if (userInfo?.userType === 'hospital') {
      navigate('/hospital-scouting');
    } else {
      navigate('/scout');
    }
  };

  if (loading) {
    return <div>Loading...</div>; // ローディング状態の表示
  }

  // 管理者ユーザーの場合にはヘッダーを表示しない
  if (userInfo?.isAdmin) {
    return (
      <>
        <Box
          as="nav"
          bg="white"
          color="white"
          position="fixed"
          top="0"
          width="100%"
          zIndex="1000"
          boxShadow="md"
        >
          <Flex
            as="nav"
            bg="white"
            color="white"
            align="center"
            justify="space-between"
            padding={{ base: 3, md: 5 }}
          >
            <Flex
              align="center"
              mr={8}
              _hover={{ cursor: 'pointer' }}
              onClick={onClickHome}
              height="50px" // 親要素の高さを確保
            >
              <Image
                src="/assets/images/logo2.png"
                alt="MEDPARK logo"
                height="160%" // 親要素に合わせて高さを調整
                objectFit="contain"
              />
            </Flex>

            <Flex
              align="center"
              fontSize="sm"
              flexGrow={2}
              display={{ base: 'none', md: 'flex' }}
            >
              <Box pr={3}>
                <NavLink
                  to="/doctor-administer"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  医師管理
                </NavLink>
              </Box>
              <Box pr={3}>
                <NavLink
                  to="/hospital-administer"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  企業管理
                </NavLink>
              </Box>
              <Box pr={3}>
                <NavLink
                  to="/spot-administer"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  スポット管理
                </NavLink>
              </Box>
              <Box pr={3}>
                <NavLink
                  to="/part-administer"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  非常勤管理
                </NavLink>
              </Box>
              {/* <Box pr={3}>
                <NavLink
                  to="/chat-administer"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  チャット管理
                </NavLink>
              </Box> */}
              {/* <Box pr={3}>
                <NavLink
                  to="/"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  コンテンツ管理
                </NavLink>
              </Box> */}
              <Box pr={3}>
                <NavLink
                  to="/setting"
                  style={{ textDecoration: 'none', color: '#26896D' }}
                >
                  会員情報
                </NavLink>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        as="nav"
        bg="white"
        color="white"
        position="fixed"
        top="0"
        width="100%"
        zIndex="1000"
        boxShadow="md"
        // height="65px"
      >
        <Flex
          as="nav"
          bg="white"
          color="#FFFFFF"
          align="center"
          justify="space-between"
          padding={{ base: 3, md: 5 }}
        >
          <Flex
            align="center"
            mr={8}
            _hover={{ cursor: 'pointer' }}
            onClick={onClickHome}
            height="50px" // 親要素の高さを確保
          >
            <Image
              src="/assets/images/logo2.png"
              alt="MEDPARK logo"
              height="160%" // 親要素に合わせて高さを調整
              objectFit="contain"
            />
          </Flex>

          <Flex
            align="center"
            fontSize="sm"
            flexGrow={2}
            display={{ base: 'none', md: 'flex' }}
          >
            {userInfo?.userType === 'hospital' ? (
              <>
                <Box pr={3}>
                  <NavLink
                    to="/work-management-for-hospital"
                    style={{
                      textDecoration: 'none',
                      color: '#036243',
                      fontWeight: 'bold',
                    }}
                  >
                    求人管理
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/work-register"
                    style={{
                      textDecoration: 'none',
                      color: '#036243',
                      fontWeight: 'bold',
                    }}
                  >
                    スポット登録
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/part-register"
                    style={{
                      textDecoration: 'none',
                      color: '#036243',
                      fontWeight: 'bold',
                    }}
                  >
                    非常勤登録
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/side-job-register"
                    style={{
                      textDecoration: 'none',
                      color: '#036243',
                      fontWeight: 'bold',
                    }}
                  >
                    副業案件登録
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/hospital-info"
                    style={{
                      textDecoration: 'none',
                      color: '#036243',
                      fontWeight: 'bold',
                    }}
                  >
                    会員情報
                  </NavLink>
                </Box>
              </>
            ) : (
              <>
                {/* <Box pr={3}>
                  <NavLink
                    to="/spot-search"
                    style={{
                      textDecoration: 'none',
                      color: '#26896D',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    スポット
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/part-search"
                    style={{
                      textDecoration: 'none',
                      color: '#26896D',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    非常勤
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/setting"
                    style={{
                      textDecoration: 'none',
                      color: '#26896D',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    マイページ
                  </NavLink>
                </Box>
                <Box pr={3}>
                  <NavLink
                    to="/login"
                    style={{
                      textDecoration: 'none',
                      color: '#26896D',
                      fontSize: '15px',
                      fontWeight: 'bold',
                    }}
                  >
                    ログイン
                  </NavLink>
                </Box> */}
              </>
            )}
          </Flex>

          <Box pr={3} color="white">
            {user ? userInfo?.name || user.displayName || 'ゲスト' : 'ゲスト'}
            さん
          </Box>

          <MenuIconButton onOpen={onOpen} />
        </Flex>
      </Box>
      <Menudrawer
        onClose={onClose}
        isOpen={isOpen}
        onClickHome={onClickHome}
        onClickLogin={onClickIslogin}
        onClickSetting={onClickSetting}
        onClickRegister={onClickRegister}
        onClickScout={onClickScout}
        onClickSpotSearch={onClickSpotSearch}
        onClickPartSearch={onClickPartSearch}
        onClickWorkManagementForDoctor={onClickWorkManagementForDoctor}
      />
    </>
  );
});
